import React, { Component } from 'react';
import {Card, Tabs, Row, Col, List} from 'antd';
import './App.css';

const TabPane = Tabs.TabPane;


class App extends Component {

    displayHomeTab(){
        let DemolayIs = [
            {
                title: "A youth-led, adult-advised organization in which young men learn to plan, organize, and facilitate their own programs."
            },
            {
                title: "An organization dedicated to making young men better people and leaders."
            },
            {
                title: "A program built on timeless principles and practical experience."
            },
            {
                title: "An Organization where all activities are 100% guided by the interests of the members."
            },
            {
                title: "An organizaiton that provides endless fun, and friendships that last a lifetime."
            },
            {

            }
        ];
        return (
            <div>
                <img src="/images/paintball.jpg" alt={""} style={{height: '250px', margin: '10px'}}/>
                <img src="/images/conclave_rodeo.jpg" alt={""} style={{height: '250px', margin: '10px'}}/>
                <img src="/images/chips2.jpg" alt={""} style={{height: '250px', margin: '10px'}}/>
                <img src="/images/waterFight.jpg" alt={""} style={{height: '250px', margin: '10px'}}/>
                <img src="/images/proclomation.jpg" alt={""} style={{height: '250px', margin: '10px'}}/>
                <h1>DeMolay Is:</h1>
                <List
                    dataSource={DemolayIs}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.title}
                                description=""
                            />
                        </List.Item>
                    )}
                />
                <p>But words are insufficient to describe DeMolay - it must be experienced!  No youth organization provides such life-changing experiences like DeMolay. In this day and age, when more and more of us are distanced by technology, why not get in touch with real people who share your value and intrests?
                </p>
            </div>
        );
    }

    displayMissionStatment(){
        return (
            <div>
                <h1>Kansas DeMolay strives to empower and mentor young men to improve themselves, their families, and their communities through brotherhood.</h1>
                <img src="http://ksdemolay.org/images/demolay.jpg" alt={""} style={{height: '250px', margin: '10px'}}/>
            </div>
        );
    }

    displaySquiresTab(){
        let Squires = [
            {
                title: "Organization providing boys age 9 to 11 an opportunity to be a part of, and support, Demolay."
            },
            {
                title: "Through Collaborative efforts with the local DeMolay chapter, squires plan their own program of activities."
            },
            {
                title: "The group supports the purpose and ideals of DeMolay through quality programs teaching leadership skills, social development and cooperative activities with the DeMolay Chapters and other local community groups. "
            },
            {
                title: " The goal of the Squires program is for its members and their parents to have fun while learning three important life lessons: Wisdom (the importance of education), Truth (honesty), and Justice (equality and fairness)."
            },
            {
                title: "By providing a fun environment and teaching three very simple lessons, the Squires will help parents draw closer to their sons and instill values that will stay with their sons throughout their lives."
            },

        ];
        return (
            <div>
                <img src="/images/squires.jpg" alt={""} style={{height: '250px'}}/>
                <h1>Squires</h1>
                <List
                    dataSource={Squires}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.title}
                                description=""
                            />
                        </List.Item>
                    )}
                />
                <p>
                    The basic Squire organization is called a “Manor.”  Every Manor is sponsored by a DeMolay Chapter or a DeMolay Jurisdiction.  Its adult leaders, who are registered DeMolay Advisors have been educated in Youth Protection and been subjected to the rigorous certification process.  In addition, when possible, there is a DeMolay member appointed to the Manor as a “Squire Mentor,” or liaison between the sponsoring chapter and the Manor.  His role is to be a big brother and example to the members of the Manor.
                </p>
            </div>
        );
    }

    displayActivitiesTab(){
        return (
            <div>
                <img src="/images/fun.jpg" alt={""} style={{height: '250px', margin: '10px'}}/>
                <img src="/images/arcade.jpg" alt={""} style={{height: '250px', margin: '10px'}}/>
                <img src="/images/saltmine.jpg" alt={""} style={{height: '250px', margin: '10px'}}/>
                <img src="/images/bumbercars.jpg" alt={""} style={{height: '250px', margin: '10px'}}/>
                <p>DeMolay Chapters are constantly organizing fun gatherings, from dances, to movie trips, to bowling trips, to sports tournaments, to anything fun you can think of doing. Because you have the ability to create your own events, you and your friends get to decide what you want to do. Board game nights? Easy. Lockins? Piece of cake. Video game tournaments? They’re everywhere. All the fun get-togethers you can dream of are possible with your leadership and the help and guidance of your advisors. Sometimes that’s just meeting up at a park to have pizza and talk about life, other times it’s having a grand, formal banquet. The sky is the limit</p>
                <p>If you’re like most young men, you’re probably going to have a lot more fun at DeMolay events when there are girls to socialize with. Luckily, there are Job’s Daughters and Rainbow Girls, which are just like DeMolay, but for young women. Job’s Daughters Bethels and Rainbow Girl Assemblies are all around the world, and they’re likely active in your area. A Masonic Youth event is the perfect environment to meet and talk to girls your age with similar interests, so be sure to ask your advisors and Chapter friends about your immediate Masonic Youth community.</p>
            </div>
        );
    }

    displayCoreValuesAndSkillsTab(){

        let valuesData = [
            {
                title: 'Filial Love',
                content: ' To understand, appreciate, and reciprocate the love our parents give to us.'
            },
            {
                title: 'Reverence for Sacred Things',
                content: 'To respect the religious beliefs of everyone, and to believe in a cause higher than ourselves.',
            },
            {
                title: 'Courtesy',
                content: 'To value every person through acceptance, empathy, respect, and tolerance.',
            },
            {
                title: 'Comradship',
                content: 'To form true and long lasting friendships far and wide.',
            },
            {
                title: 'Fidilety',
                content: 'To be true, trustworthy, and honest to yourself and your values.',
            },
            {
                title: 'Cleanliness',
                content: 'To be clean in thought, word, and deed.',
            },
            {
                title: 'Patriotism',
                content: 'To honor your country and be a contributing citizen.  To respect those who have and continue to serve our Country in every field of sacrifice and service.',
            }
        ];
        let skillsData = [
            {
                title: 'Leadership',
                content: ''
            },
            {
                title: 'Communication',
                content: ''
            },
            {
                title: 'Event Planning',
                content: ''
            },
            {
                title: 'Team Work',
                content: ''
            },
        ];
        return (
            <div>
                <Col span={12}>
                    <Card title="Core Values" bordered={true} style={{margin: '10px'}}>
                        <List
                            dataSource={valuesData}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={item.title}
                                        description={item.content}
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Skills Learned" bordered={true} style={{margin: '10px'}}>
                        <List
                            dataSource={skillsData}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={item.title}
                                        description={item.content}
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>
            </div>
        )
    }

    displayLocationsTab(){
        return (
            <div style={{  padding: '30px' }}>
                <Card title="Crusaders Chapter" bordered={true}>
                    <Card title="Location" bordered={false}>
                        Leon, KS
                    </Card>
                    <Card title={"Meeting Nights"} bordered={false}>
                        2nd and 4th Sundays at 6:30 p.m.
                    </Card>
                    <Card title={"Contact Email"} bordered={false}>
                        Brent (buckleymbrent@gmail.com)
                    </Card>
                </Card>
                <img src="/images/deleware.jpg" alt={""} style={{height: '250px', 'margin-bottom': '10px', 'margin-top': '10px'}}/>
                <Card title="Delaware Chapter" bordered={true}>
                    <Card title="Location" bordered={false}>
                        Kansas City, KS
                    </Card>
                    <Card title={"Meeting Nights"} bordered={false}>
                        1st and 3rd Thursdays at 7 p.m.
                    </Card>
                    <Card title={"Contact Email"} bordered={false}>
                        Phil (phillipgicalone@sbcglobal.net)
                    </Card>
                </Card>
                <img src="/images/excalibur.jpg" alt={""} style={{height: '250px', 'margin-bottom': '10px', 'margin-top': '10px'}}/>
                <Card title="Excalibur Chapter" bordered={true}>
                    <Card title="Location" bordered={false}>
                        Wichita, KS
                    </Card>
                    <Card title={"Meeting Nights"} bordered={false}>
                        1st and 3rd Tuesdays
                    </Card>
                    <Card title={"Contact Email"} bordered={false}>
                        Lucas (lucastodd3537@outlook.com)
                    </Card>
                </Card>
                <img src="/images/kingaurthur.jpg" alt={""} style={{height: '250px', 'margin-bottom': '10px', 'margin-top': '10px'}}/>
                <Card title="King Arthur Chapter" bordered={true}>
                    <Card title="Location" bordered={false}>
                        Coffeyville, KS
                    </Card>
                    <Card title={"Contact Email"} bordered={false}>
                        David (dgivens716@hotmail.com)
                    </Card>
                </Card>
                <Card title="Merlin Chapter" bordered={true}>
                    <Card title="Location" bordered={false}>
                        Hutchinson, KS
                    </Card>
                    <Card title={"Meeting Nights"} bordered={false}>
                        1st and 3rd Sundays at 4 p.m.
                    </Card>
                    <Card title={"Contact Email"} bordered={false}>
                        Bethany (bethanyroberts14@gmail.com)
                    </Card>
                </Card>
                <Card title="Percival Chapter" bordered={true}>
                    <Card title="Location" bordered={false}>
                        Topeka, KS
                    </Card>
                    <Card title={"Contact Email"} bordered={false}>
                        Matt (Sarge.rutowski@gmail.com)
                    </Card>
                </Card>
                <img src="/images/roundtable.jpg" alt={""} style={{height: '250px', 'margin-bottom': '10px', 'margin-top': '10px'}}/>
                <Card title="Round Table Chapter" bordered={true}>
                    <Card title="Location" bordered={false}>
                        Topeka, KS
                    </Card>
                    <Card title={"Meeting Nights"} bordered={false}>
                        1st and 3rd Sundays at 7 p.m.
                    </Card>
                    <Card title={"Contact Email"} bordered={false}>
                        Matt (Sarge.rutowski@gmail.com)
                    </Card>
                </Card>
            </div>
        )
    }

    displayCalendarTab(){
        return (
            <iframe
                title="calendar"
                src="https://calendar.google.com/calendar/embed?src=ksdemolay1919%40gmail.com&ctz=America%2FChicago"
                style={{border: 0}}
                width="100%"
                height="600"
                frameBorder="0"
                scrolling="no"
            />
        );
    }

    displayMemberPortalTab(){
        let url = window.location.href.split("/");
        let urlIndex = -1;
        for(let index=0; index<url.length; index++){
            if(url[index] === "#" && url[index+2] && url[index+1] === "memberPortal"){
                urlIndex = index+2;
                index = url.length;
            }
        }
        let defaultActiveKey = (urlIndex > -1) ? url[urlIndex] : "leaders";
        return (
            <Tabs
                defaultActiveKey={defaultActiveKey}
                tabPosition={"top"}
                style={{'textAlign': 'center', color: 'black'}}
                onChange={this.updateSecondaryURL}
            >
                <TabPane tab="Past State Master Councilors" key="psmc">{this.displayPSMCSubTab()}</TabPane>
                <TabPane tab="Past Executive Officers" key="pasteo">{this.displayPEOSubTab()}</TabPane>
                <TabPane tab="Useful Sites" key="sites">{this.displayUsefulSitesSubTab()}</TabPane>
            </Tabs>
        );
        return (
            <Tabs
                defaultActiveKey={defaultActiveKey}
                tabPosition={"top"}
                style={{'textAlign': 'center', color: 'black'}}
                onChange={this.updateSecondaryURL}
            >
                <TabPane tab="State Leaders" key="leaders">{this.displayStateOfficersSubTab()}</TabPane>
                <TabPane tab="State Staff" key="staff">{this.displayStateStaffSubTab()}</TabPane>
                <TabPane tab="Past State Master Councilors" key="psmc">{this.displayPSMCSubTab()}</TabPane>
                <TabPane tab="Past Executive Officers" key="pasteo">{this.displayPEOSubTab()}</TabPane>
                <TabPane tab="Useful Forms" key="forms">{this.displayUsefulFormsSubTab()}</TabPane>
                <TabPane tab="Useful Sites" key="sites">{this.displayUsefulSitesSubTab()}</TabPane>
                <TabPane tab="News Feed" key="newsletters">{this.displayNewslettersSubTab()}</TabPane>
            </Tabs>
        );
        //                <TabPane tab="Past Officer & Award Recipients" key="2">{this.displayPastOfficerAndAwardSubTab()}</TabPane>
    }

    displayStateOfficersSubTab(){
        let StateLeaders = [
            {
                "Name": "Ian Jaminson",
                "Title": "State Master Councilor",
                "Chapter": "Round Table Chapter",
                "Bio": "Ian Jamison joined Round Table Chapter of DeMolay in 2010 and is the current State Master Councilor for Kansas DeMolay. He is a senior at Wichita State University majoring in Sport Management, and assistant boys' basketball coach for Life Prep Academy in Wichita. He loves basketball, and his favorite parts of state conclave are the sports competitions and catching up with all of his DeMolay brothers from across the state.",
                "imageURL": "/resources/images/Ian.jpg"
            },
            {
                "Name": "Lizzy",
                "Title": "State Sweetheart",
                "Chapter": "Delaware Chapter",
                "Bio": "I am so thrilled and honored to be chosen as the State Sweetheart of Kansas DeMolay. I will be a Freshman at the University of Kansas and am excited to start this new chapter of my life as a college student. I have been the Chapter Sweetheart of the Delaware chapter for 2 years and am looking forward the years ahead as I continue to serve DeMolay as a sweetheart no matter the rank. I love being able to help the young men plan their events for the term and cheering them on when they achieve their goals.",
                "imageURL": "/resources/images/Lizzy.jpg"
            },
            {
                "Name": "Sheriden",
                "Title": "Deputy State Master Councilor",
                "Chapter": "Excalibur Chapter",
                "Bio": "Sheriden Doyel joined Excalibur Chapter when his family moved to Kansas in 2014 and served as Master Councilor in 2017-18. He's been lucky enough to visit Hawaii Chapter in Honolulu and the Broomfield and Golden Chapters in Colorado. He has plans to do a lot of traveling during the next two years.\n" +
                    "\n" +
                    "He's a senior at Wichita Heights and plans to continue his education in computer sciences. He loves gaming and anything with electronics. He lives with his mom, step-dad Richard, and sister Stephanie in Park City.\n" +
                    "\n" +
                    "When not busy with DeMolay he'a won state and national competitions in the Business Professionals of America (a school club) using his computer knowledge and skills. He will continue participating in that this year.",
                "imageURL": "/resources/images/Sheridan.jpg"
            },
            {
                "Name": "Abby",
                "Title": "Associate State Sweetheart",
                "Chapter": "Round Table Chapter",
                "Bio": "Tiana Smith was honored to be elected Kansas DeMolay State Associate Sweetheart at the 2019 State Conclave. She has been around DeMolay since her older brother joined in 2009, and she enjoys participating in events with the boys and helping with their conclave. She is a sophomore at Lawrence Virtual High School, plays percussion, and is a member of the Rainbow Phoenix Assembly #80 in Topeka and Grand Drill Leader for Kansas Rainbows.",
                "imageURL": "/resources/images/Tiana.jpg"
            }
        ];
        return (
            <List
                grid={{ gutter: 16, column: 2 }}
                dataSource={StateLeaders}
                renderItem={item => (
                    <List.Item>
                        <Card
                            title={item.Name}
                            description={item.Bio}
                        >
                            {item.Title}
                            <br/><br/>
                            {item.Chapter}
                            <br/><br/>
                            <img src={item.imageURL} width="225px" height="300px"/>
                            <br/><br/>
                            {item.Bio}
                        </Card>
                    </List.Item>
                )}
            />
        );
    }

    //TODO implement once new tabs are done
    displayPastOfficerAndAwardSubTab(){
        return (
            <Tabs
                defaultActiveKey="1"
                tabPosition={"top"}
                style={{'textAlign': 'center', color: 'black'}}
            >
                <TabPane tab="Past State Master Councilors" key="1">{this.displayStateOfficersSubTab()}</TabPane>
                <TabPane tab="Past Officer & Award Recipients" key="2">{this.displayPastOfficerAndAwardSubTab()}</TabPane>
                <TabPane tab="State Staff" key="3">{this.displayStateStaffSubTab()}</TabPane>
                <TabPane tab="Useful Forms" key="4">{this.displayUsefulFormsSubTab()}</TabPane>
                <TabPane tab="Useful Sites" key="5">{this.displayUsefulSitesSubTab()}</TabPane>
                <TabPane tab="Newsleters" key="6">{this.displayNewslettersSubTab()}</TabPane>
            </Tabs>
        );
    }


    displayStateStaffSubTab(){
        /*
        Director of Legion Of Honor Eldon Masters
        Director of Chevaliers Mike Haxton
        Director of Alumini Robert Pfuetze
         */
        let StateStaff = [
            {
                "Name": "Dad Mark McClain",
                "Title":"Personal Representative"
            },
            {
                "Name": "Dad Brent Buckley",
                "Title":"State Chapter Dad"
            },
            {
                "Name": "To Be Determined",
                "Title": "Director of Squires"
            },
            {
                "Name": "Amanda Dockens",
                "Title": "Director of Sweethearts"
            },
            {
                "Name": "Dad Dennis Reed",
                "Title":"State Treasurer"
            },
            {
                "Name": "Dad Steve Crane",
                "Title":"Director of Membership"
            },
            {
                "Name": "Dad Mark Stephenson",
                "Title": "Director of NE KS"
            },
            {
                "Name": "Dad Kendall Garton",
                "Title":"Director of SE KS"
            },
            {
                "Name": "Mom Jennifer Reed",
                "Title":"Director of Conclave"
            },
            {
                "Name": "Dad Robert Talbott",
                "Title":"Director of Masonic Relations"
            },
            {
                "Name": "Dad Galen Graves",
                "Title":"Director of Ritual"
            },
            {
                "Name": "Dad Eldon Masters",
                "Title": "Director of Legion of Honor"
            },
            {
                "Name": "Dad Mike Haxton",
                "Title": "Director of Chevaliers"
            },
            {
                "Name":  "Mom Kristen Mies-Martinez",
                "Title": "Director of Social Media"
            },
            {
                "Name": "Dad David Barnes III",
                "Title":"State Webmaster"
            },
            /*
            {
                "Name": "Dad David Barnes II",
                "Title": "Executive Officer",
            },
            {
                "Name": "Dad Gregory Marriott",
                "Title":"Assistant State Chapter Dad"
            },
            {
                "Name": "Dad Jesse Barnes",
                "Title":"State Squire Director and Assistant State Chapter Dad"
            },
            {
                "Name": "Dad Dylan Lester",
                "Title":"Assistant Ritual Director"
            },
            {
                "Name": "Dad Glenn Graves",
                "Title":"Development Coordinator"
            },
            */

        ];
        return (
            <List
                grid={{ gutter: 16, column: 3 }}
                dataSource={StateStaff}
                renderItem={item => (
                    <List.Item>
                        <Card
                            title={item.Name}
                        >
                            {item.Title}
                        </Card>
                    </List.Item>
                )}
            />
        );
    }


    displayPSMCSubTab(){
        let pastStateLeaders = [
            {
                "Title": "1920s",
                "Officers": [
                    {
                        "Name": "Bryon R. Ward",
                        "Year": "1924",
                        "Chapter": "Huron Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "T. Wayne Harris",
                        "Year": "1925",
                        "Chapter": "Independence Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Milton C. Tainter",
                        "Year": "1926",
                        "Chapter": "Olathe Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "W. Harold Mooney",
                        "Year": "1927",
                        "Chapter": "Wichita Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Marvin F. Songer",
                        "Year": "1928",
                        "Chapter": "El Dorado Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Kenneth E. Boyer",
                        "Year": "1929",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                ]
            },
            {
                "Title": "1930s",
                "Officers": [
                    {
                        "Name": "James D. Norcross",
                        "Year": "1930",
                        "Chapter": "Huron Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Freemont J. Knittle",
                        "Year": "1931",
                        "Chapter": "Salina Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Charles B. Lutz",
                        "Year": "1932",
                        "Chapter": "Hutchinson Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "A. LaVerne Litmer",
                        "Year": "1933",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Warner H. Sorenson",
                        "Year": "1934",
                        "Chapter": "William Rankin Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "William H. Bleeha",
                        "Year": "1935",
                        "Chapter": "Arkansas City Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Seth I. Root",
                        "Year": "1936",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Robert S. Dorsey",
                        "Year": "1937",
                        "Chapter": "Pittsburg Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Chett A. Eckman",
                        "Year": "1938",
                        "Chapter": "Huron Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Edmund M. Jacoby",
                        "Year": "1939",
                        "Chapter": "Wichita Chapter",
                        "Extra": ""
                    },
                ]
            },
            {
                "Title": "1940s",
                "Officers": [
                    {
                        "Name": "Donald Ee. Slagle",
                        "Year": "1940",
                        "Chapter": "Pittsburg Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "George E. Wardrum",
                        "Year": "1941",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Myron E. Neal",
                        "Year": "1942",
                        "Chapter": "Wichita Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Robert G. Coshow",
                        "Year": "No Conclaves During WWII",
                        "Chapter": "Hutchinson Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "David L. Blair",
                        "Year": "1948",
                        "Chapter": "Franklin County Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Robert J. Harrison, Ph.D.",
                        "Year": "1949",
                        "Chapter": "Wichita Chapter",
                        "Extra": ""
                    },
                ]
            },
            {
                "Title": "1950s",
                "Officers": [
                    {
                        "Name": "Ted M. Templar",
                        "Year": "1950",
                        "Chapter": "Arkansas City Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Robert F. Kinard",
                        "Year": "1951",
                        "Chapter": "Dodge City Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "William B. Wright",
                        "Year": "1952",
                        "Chapter": " Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Donald A. Johnston",
                        "Year": "1953",
                        "Chapter": "Pittsburg Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "William L. Woods",
                        "Year": "1954",
                        "Chapter": "Arkansas City Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Donald R. Sledd",
                        "Year": "1955",
                        "Chapter": "Lyons Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Col. Larry G. Coker",
                        "Year": "1956",
                        "Chapter": "Osawatomie Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Jerry R. Watson",
                        "Year": "1957",
                        "Chapter": "Pittsburg Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Capt. Albert L. Snyder",
                        "Year": "1958",
                        "Chapter": "Wichita Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Warren W. Norton",
                        "Year": "1959",
                        "Chapter": "Frank A. Marshall Chapter",
                        "Extra": ""
                    },
                ]
            },
            {
                "Title": "1960s",
                "Officers": [

                    {
                        "Name": "Dennis A. Daugherty",
                        "Year": "1960",
                        "Chapter": "Shawnee Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Albert A. Walters",
                        "Year": "1961",
                        "Chapter": "Wichita Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Charles Richard Grant",
                        "Year": "1962",
                        "Chapter": "Osawatomie Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "James B. Martin, Jr.",
                        "Year": "1963",
                        "Chapter": "Wichita Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Allen E. Wann",
                        "Year": "1964",
                        "Chapter": "Liberal Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Walter H. Mooney, J.D.",
                        "Year": "1965",
                        "Chapter": "Wichita Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "John T. Smith",
                        "Year": "1966",
                        "Chapter": "Frank S. Land Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "James M. Conncannon, J.D.",
                        "Year": "1967",
                        "Chapter": "Garden City Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "William C. Rapp",
                        "Year": "1968",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                ]
            },
            {
                "Title": "1970s",
                "Officers": [
                    {
                        "Name": "James N. Harrington",
                        "Year": "1970",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Phillip K. Hooper",
                        "Year": "1971",
                        "Chapter": "Sir Galahad Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Kent V. Snyder, J.D.",
                        "Year": "1972",
                        "Chapter": "Old Mission Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Jeffery F. Crandell",
                        "Year": "1973",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Bobb A. Meekenstock",
                        "Year": "1974",
                        "Chapter": "Frank S. Land Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "George A. Gallup",
                        "Year": "1975",
                        "Chapter": "Blue Rapids Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "James H. Cash",
                        "Year": "1976",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "David M. Stout, M.D.",
                        "Year": "1977",
                        "Chapter": "Frank S. Land Chapter",
                        "Extra": "Past International Master Councilor"
                    },
                    {
                        "Name": "John E. Cunningham Jr.",
                        "Year": "1978",
                        "Chapter": "Old Mission Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Charles A. Etherington J.D.",
                        "Year": "1979",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                ]
            },
            {
                "Title": "1980s",
                "Officers": [
                    {
                        "Name": "Patric L. Clark",
                        "Year": "1980",
                        "Chapter": "Frank S. Land Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Richard A. Etherington",
                        "Year": "1981",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "James R. Bloss, J.D.",
                        "Year": "1982",
                        "Chapter": "Frank S. Land Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Major C. Weiss",
                        "Year": "1983",
                        "Chapter": "Olathe Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "David T. Taggart",
                        "Year": "1984",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Darrell E Spain, J.D.",
                        "Year": "1985",
                        "Chapter": "Blue Rapids Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Jay A. Wiegman, J.D.",
                        "Year": "1986",
                        "Chapter": "Frank S. Land Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "James R. Giesen",
                        "Year": "1987",
                        "Chapter": "Morgan Cox Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Hugh W. Gill IV, J.D.",
                        "Year": "1988",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Kevin E. Dellett, J.D.",
                        "Year": "1989",
                        "Chapter": "Frank S. Land Chapter",
                        "Extra": ""
                    },
                ]
            },
            {
                "Title": "1990s",
                "Officers": [
                    {
                        "Name": "C. Edward Young, J.D.",
                        "Year": "1990",
                        "Chapter": "Olathe Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Alan E. Smith",
                        "Year": "1991",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "S. Lance (Smith) Freije, J.D.",
                        "Year": "1992",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Robert C. Watson",
                        "Year": "1993",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Jermey J. Rush",
                        "Year": "1994",
                        "Chapter": "Morgan Cox Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Jason J. Barton",
                        "Year": "1995",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Galen G. Graves",
                        "Year": "1996",
                        "Chapter": "Old Mission Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Douglas S. Bourdreau",
                        "Year": "1997",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Jeff R. English",
                        "Year": "1998",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": "Past International Master Councilor"
                    },
                    {
                        "Name": "Rick A. Wooten",
                        "Year": "1999",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                ]
            },
            {
                "Title": "2000s",
                "Officers": [
                    {
                        "Name": "Ronald A. Shively",
                        "Year": "2000",
                        "Chapter": "Morgan Cox Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Bradley G. Englishy",
                        "Year": "2001",
                        "Chapter": "Bestor G. Brown Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "David W. Hooper, Jr.",
                        "Year": "2002",
                        "Chapter": "Old Mission Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Sean R. Murphy",
                        "Year": "2003",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Craig A. Reed",
                        "Year": "2004",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Josh Albin",
                        "Year": "2005",
                        "Chapter": "Johnson County Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Travis Atchison",
                        "Year": "2006",
                        "Chapter": "Excalibur Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "David Grandstaff",
                        "Year": "2007",
                        "Chapter": "Excalibur Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Adrian Kenny",
                        "Year": "2008",
                        "Chapter": "Excalibur Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Brent Buckley",
                        "Year": "2009",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                ]
            },
            {
                "Title": "2010s",
                "Officers": [
                    {
                        "Name": "Dylan Lester",
                        "Year": "2010",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "David E. Barnes III",
                        "Year": "2011, 2013 & 2014",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Gregory A. Marriott",
                        "Year": "2012 & 2015",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Jesse L. Barnes",
                        "Year": "2016 & 2017",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Scott Kirk",
                        "Year": "2018",
                        "Chapter": "Delaware Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "C.M.",
                        "Year": "2019 & 2021",
                        "Chapter": "Excalibur Chapter",
                        "Extra": ""
                    },
                ]
            },
            {
                "Title": "2020s",
                "Officers": [
                    {
                        "Name": "Ian Jaminson",
                        "Year": "2020",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Aaron Longberg",
                        "Year": "2022",
                        "Chapter": "Round Table Chapter",
                        "Extra": ""
                    },
                    {
                        "Name": "Leo Barnes",
                        "Year": "2023",
                        "Chapter": "Round Table & Delaware Chapters",
                        "Extra": ""
                    },
                ]
            },
        ];
        return (
            <List
                dataSource={pastStateLeaders}
                renderItem={item => (
                    <List.Item>
                        <Card title={item.Title}>
                            <List
                                grid={{ gutter: 16, column: 4 }}
                                dataSource={item.Officers}
                                renderItem={item => (
                                    <List.Item>
                                        <Card title={item.Name}>
                                            {item.Year}
                                            <br/>
                                            {item.Chapter}
                                            <br/>
                                            {item.Extra}
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </List.Item>
                )}
            />
        );
    }

    displayPEOSubTab(){
        let PastEOs = [
            {
                "Name": "D. Clarke Kelly (1920 - 1954)",
                "ExtraFirst":"",
                "ExtraSecond":""
            },
            {
                "Name": "D. Bruce Newton (1954 - 1966)",
                "ExtraFirst":"",
                "ExtraSecond":""
            },
            {
                "Name": "Thomas C. Raum Jr (1966 - 1981)",
                "ExtraFirst":"Past Grand Master of DeMolay International (1978 - 1979)",
                "ExtraSecond":"Past Grand Master of Grand Lodge of AF&AM of KS"
            },
            {
                "Name": "Dennis A Daugherty (1981 - 1984)",
                "ExtraFirst":"",
                "ExtraSecond":""
            },
            {
                "Name": "J. Eldon Masters (1985 - 2003)",
                "ExtraFirst":"",
                "ExtraSecond":""
            },
            {
                "Name": "Steven A. Crane (2003 - 2007)",
                "ExtraFirst":"Past Grand Master of DeMolay International (2015 - 2016)",
                "ExtraSecond":""
            },
            {
                "Name": "Dennis L. Reed (2007 - 2010)",
                "ExtraFirst":"",
                "ExtraSecond":""
            },
            {
                "Name": 'William "Buck" R. Fischer (2010 - 2013)',
                "ExtraFirst":"",
                "ExtraSecond":""
            },
            {
                "Name": "David Barnes II (2013 - 2019)",
                "ExtraFirst": "",
                "ExtraSecond": ""
            },
            {
                "Name": "Mark McClain (2019 - Present)",
                "ExtraFirst": "",
                "ExtraSecond": ""
            }
        ];
        return (
            <List
                grid={{ gutter: 16, column: 2 }}
                dataSource={PastEOs}
                renderItem={item => (
                    <List.Item>
                        <Card title={item.Title}>
                            <Card title={item.Name}>
                                Past Executive Officer
                                <br/><br/>
                                {item.ExtraFirst}
                                <br/><br/>
                                {item.ExtraSecond}
                            </Card>
                        </Card>
                    </List.Item>
                )}
            />
        )
    }

    displayUsefulFormsSubTab(){
        let usefulForms = [
            {
                "Title": "General Forms",
                "Links": [
                    {
                        "Title": "Medical Release Form",
                        "Link": "http://members.ksdemolay.org/pdf/medical.pdf"
                    },
                    {
                        "Title":"Kansas DeMolay By-Laws",
                        "Link":"http://members.ksdemolay.org/pdf/bylaw.pdf"
                    },
                ]
            },
            {
                "Title": "Applications",
                "Links": [
                    {
                        "Title":"DeMolay",
                        "Link":"http://members.ksdemolay.org/pdf/demolay_petition.pdf"
                    },
                    {
                        "Title":"Squires",
                        "Link":"http://members.ksdemolay.org/pdf/squires_petition.pdf"
                    },
                ]
            },
            {
                "Title": "Awards",
                "Links": [
                    {
                        "Title":"DeMolay of the Year",
                        "Link":"http://members.ksdemolay.org/pdf/demolayOfYear.pdf"
                    },
                    {
                        "Title":"Chapter of the Year",
                        "Link":"http://members.ksdemolay.org/pdf/chapterOfYear.pdf"
                    },
                    {
                        "Title":"Representative DeMolay (RD)",
                        "Link":"http://members.ksdemolay.org/pdf/representative_demolay_form.pdf"
                    },
                ]
            },
            {
                "Title": "Ritual Competition Forms",
                "Links": [
                    {
                        "Title":"Universal Ritual Entry Form",
                        "Link":"http://members.ksdemolay.org/pdf/ritualEvent.pdf"
                    },
                ]
            },
            {
                "Title": "Membership Drive Information",
                "Links": [
                    {
                        "Title":"membership 2016 Packet",
                        "Link":"http://members.ksdemolay.org/pdf/membership.pdf"
                    },
                    {
                        "Title":"4 Step Process",
                        "Link":"http://members.ksdemolay.org/4stepProcess.docx"
                    },
                    {
                        "Title":"Joining Ceremony Outline",
                        "Link":"http://members.ksdemolay.org/joiningceremony.docx"
                    },
                    {
                        "Title":"Sample Invitational Letter",
                        "Link":"http://members.ksdemolay.org/sampleinvite.docx"
                    },
                    {
                        "Title":"Sample Phone Messages",
                        "Link":"http://members.ksdemolay.org/doc/samplePhoneMessage.docx"
                    },
                ]
            },
            {
                "Title": "Ad Sale Forms",
                "Links":[
                    {
                        "Title": "KMF Matching Funds Form",
                        "Link": "http://members.ksdemolay.org/wp-content/uploads/2020/02/KMF-Charities-Application-Fillable.pdf"
                    },
                    {
                        "Title": "Booster Recognition Page Sample",
                        "Link": "http://members.ksdemolay.org/wp-content/uploads/2020/02/Sponsor-Booster-Example.xlsx"
                    },
                    {
                        "Title": "Ad Sales Letter For Organizations",
                        "Link": "http://members.ksdemolay.org/wp-content/uploads/2020/02/Ad-Sales-Letter-for-Organizations.docx"
                    },
                    {
                        "Title": "Sample Ad Sponsor Letter",
                        "Link": "http://members.ksdemolay.org/wp-content/uploads/2020/02/2020-Ad-Sponsor-Letter.docx"
                    }
                ]
            },
            {
                "Title": "State Sweetheart Application",
                "Links": [
                ]
            },
        ];
        return (
            <List
                grid={{ gutter: 16, column: 3 }}
                dataSource={usefulForms}
                renderItem={item => (
                    <List.Item>
                        <Card title={item.Title}>
                            <List
                                dataSource={item.Links}
                                renderItem={link => (
                                    <List.Item>
                                        <a href={link.Link}>{link.Title}</a>
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </List.Item>
                )}
            />
        );
    }

    displayUsefulSitesSubTab(){
        let usefulSites = [
            {
                "Title": "DeMolay International",
                "Links": [
                    {
                        "Title": "DeMolay International",
                        "Link": "https://www.demolay.org"
                    },
                    {
                        "Title": "DeMolay Escribe",
                        "Link": "https://escribe.demolay.org/login.php"
                    },
                    {
                        "Title": "DeMolay Forms",
                        "Link": "https://demolay.org/resources/forms/"
                    },
                    {
                        "Title": "Frank Marshall Championship Tournament",
                        "Link": "http://frankmarshall.org/wp/"
                    },
                    {
                        "Title": "Open Ceremonies",
                        "Link": "https://demolay.org/open-ceremonies/"
                    }
                ]
            },
            {
                "Title": "Scholarship Information",
                "Links": [
                    {
                        "Title": "General DeMolay Scholarships",
                        "Link": "https://demolay.org/scholarships/"
                    },
                    {
                        "Title": "DeMolay Kach Essay Scholarship",
                        "Link": "https://demolay.org/kach-essay/"
                    },
                    {
                        "Title": "Kansas Masonic Foundation Scholarship",
                        "Link": "https://kansasmasonic.foundation/program/kansas-masonic-foundation-scholarships/"
                    },
                    {
                        "Title": "Grand York Rite Scholarship",
                        "Link": "http://uploads.documents.cimpress.io/v1/uploads/85bb8293-b087-4372-bbc5-9f98513875b2~110/original?tenant=vbu-digital"
                    },
                    {
                        "Title": "Grand Chapter OES Scholarships",
                        "Link": "https://www.kansasgrandchapteroes.com/scholarship-awards/"
                    }
                ]
            },
            {
                "Title": "Other Masonic Bodies in Kansas",
                "Links": [
                    {
                        "Title": "Kansas Jobs Daughters",
                        "Link": "https://www.ksjdi.org/"
                    },
                    {
                      "Title": "Kansas Rainbow Girls",
                      "Link": "http://ksrainbow.org/?fbclid=IwAR1xE2-plAO1n-jrs3oGAxDnoPHs3Du-jpPGYY9y9w5ETiOmSRuWIyps30s"
                    },
                    {
                        "Title": "Grand Lodge of KS AF&AM",
                        "Link": "https://www.kansasmason.org/"
                    },
                    {
                        "Title": "Grand Chapter OES Kansas",
                        "Link": "https://www.kansasgrandchapteroes.com/"
                    },
                    {
                        "Title": "Grand York Rite of Kansas",
                        "Link": "https://kansasyorkrite.org/"
                    }
                ]
            }
        ];
        return (
            <List
                grid={{ gutter: 16, column: 3 }}
                dataSource={usefulSites}
                renderItem={item => (
                    <List.Item>
                        <Card title={item.Title}>
                            <List
                                dataSource={item.Links}
                                renderItem={link => (
                                    <List.Item>
                                        <a href={link.Link}>{link.Title}</a>
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </List.Item>
                )}
            />
        );
    }

    //todo finish
    displayNewslettersSubTab(){
        let usefulForms = [
            {
                "Title": "",
                "Links": [
                ]
            }
        ];
        return (
            <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={usefulForms}
                renderItem={item => (
                    <List.Item>
                        <Card>
                            <iframe src="https://ksdemolay.blogspot.com/" height="1000" width="100%"></iframe>
                        </Card>
                    </List.Item>
                )}
            />
        );
    }

    updateSecondaryURL(index){
        window.location.href = "/#/memberPortal/"+index;
    }

    updateURL(index){
        window.location.href = "/#/"+index;
    }

    render() {
        let url = window.location.href.split("/");
        let urlIndex = -1;
        for(let index=0; index<url.length; index++){
            if(url[index] === "#"){
                urlIndex = index+1;
                index = url.length;
            }
        }
        let defaultActiveKey = (urlIndex > -1) ? url[urlIndex] : "home";
        if(urlIndex === -1){
            window.location.href = "/#/home";
        }
        return (
            <div className="App">
                <div style={{'min-width': '640px'}}>
                    <Card>
                        <img src="http://ksdemolay.org/images/logo2.jpeg" alt={""}  height="200em"/>
                        <Tabs defaultActiveKey={defaultActiveKey} tabPosition={"top"} style={{'textAlign': 'center', color: 'black'}} onChange={this.updateURL}>
                            <TabPane tab="Home" key="home">{this.displayHomeTab()}</TabPane>
                            <TabPane tab="Squires" key="squires">{this.displaySquiresTab()}</TabPane>
                            <TabPane tab="Activities" key="Activities">{this.displayActivitiesTab()}</TabPane>
                            <TabPane tab="Core Values & Skills Learned" key="valueSkills">{this.displayCoreValuesAndSkillsTab()}</TabPane>
                            <TabPane tab="Locations" key="locations">{this.displayLocationsTab()}</TabPane>
                            <TabPane tab="Member Information" key="memberPortal">{this.displayMemberPortalTab()}</TabPane>
                        </Tabs>
                        <div>
                            <a href={"https://www.facebook.com/kansasdemolay"} class={"fa fa-facebook"}></a>
                        </div>
                    </Card>
                </div>
            </div>
        );
        return (
            <div className="App">
                <div style={{'min-width': '640px'}}>
                    <Card>
                        <img src="http://ksdemolay.org/images/logo2.jpeg" alt={""}  height="200em"/>
                        <Tabs defaultActiveKey={defaultActiveKey} tabPosition={"top"} style={{'textAlign': 'center', color: 'black'}} onChange={this.updateURL}>
                            <TabPane tab="Home" key="home">{this.displayHomeTab()}</TabPane>
                            <TabPane tab="Squires" key="squires">{this.displaySquiresTab()}</TabPane>
                            <TabPane tab="Activities" key="Activities">{this.displayActivitiesTab()}</TabPane>
                            <TabPane tab="Core Values & Skills Learned" key="valueSkills">{this.displayCoreValuesAndSkillsTab()}</TabPane>
                            <TabPane tab="Locations" key="locations">{this.displayLocationsTab()}</TabPane>
                            <TabPane tab="Calendar" key="calendar">{this.displayCalendarTab()}</TabPane>
                            <TabPane tab="Member Information" key="memberPortal">{this.displayMemberPortalTab()}</TabPane>
                        </Tabs>
                        <div>
                            <a href={"https://www.facebook.com/kansasdemolay"} class={"fa fa-facebook"}></a>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

export default App;